<template>
  <Page :locked="locked" :title="'Você ainda não possui este canal ativo'" :message="'O RCS ajuda você a enviar conteúdo rico diretamente para o aplicativo Mensagens do seu cliente. Compartilhe fotos, arquivos e eventos, saiba quando as mensagens são lidas e construa um relacionamento com seus clientes.'" :features="features" :featureTitle="'O que tem no RCS?'" @unsubscribeIt="subscribeRCS">
    <PageHeader :title="'RCS Google'" :subtitle="$t('generic-str.send')" />
    <div class="page-content container-fluid new-template-content">
      <div class="row">
        <!-- Left column -->
        <div class="col-lg-7">
          <div class="card card-tabs">
            <div class="card-header">
              <div class="card-header-left">
                <div class="flex-row">
                  <div>
                    <div class="card-header-photo"></div>
                  </div>
                  <div style="margin-left: 10px;" class="c-point" v-b-toggle.sidebar-right-numbers>
                    <div class="c-pointer info-whatsapp-button">
                      <span class="card-header-name">{{this.form.agent_name}}</span>
                      <span class="card-header-number">Escolher</span>
                      <span class="material-symbols-outlined card-header-sync">sync</span>
                    </div>
                    <div class="card-header-number-text">
                      Agente
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-header-right" v-b-toggle.sidebar-right-template>
                <div>
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2330_41368)">
                      <path
                        d="M7.15039 21H11.1504C11.1504 22.1 10.2504 23 9.15039 23C8.05039 23 7.15039 22.1 7.15039 21ZM5.15039 20H13.1504V18H5.15039V20ZM16.6504 10.5C16.6504 14.32 13.9904 16.36 12.8804 17H5.42039C4.31039 16.36 1.65039 14.32 1.65039 10.5C1.65039 6.36 5.01039 3 9.15039 3C13.2904 3 16.6504 6.36 16.6504 10.5ZM14.6504 10.5C14.6504 7.47 12.1804 5 9.15039 5C6.12039 5 3.65039 7.47 3.65039 10.5C3.65039 12.97 5.14039 14.39 6.00039 15H12.3004C13.1604 14.39 14.6504 12.97 14.6504 10.5ZM21.5204 8.37L20.1504 9L21.5204 9.63L22.1504 11L22.7804 9.63L24.1504 9L22.7804 8.37L22.1504 7L21.5204 8.37ZM19.1504 7L20.0904 4.94L22.1504 4L20.0904 3.06L19.1504 1L18.2104 3.06L16.1504 4L18.2104 4.94L19.1504 7Z"
                        id="light-svg" fill="#16CD08" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2330_41368">
                        <rect width="24" height="24" fill="white" transform="translate(0.900391)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span>Selecionar template</span>
              </div>
            </div>
            <div class="card-body">
              <div class="form-body">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tab-content">
                      <div class="tab-pane active" id="simple">
                        <div class="form-group row">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">menu</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <label class="mb-4">Escolha o tipo de mensagem:</label>
                            <div class="header-wrapper">
                              <b-card no-body>
                                <b-card-header header-tag="header" :class="{'active':form.type == 'TEXT',}">
                                  <img src="@/assets/skeleton-text.svg" alt="Skeleton Text" />
                                  <button class="btn btn-choose-type stretched-link" @click="form.type = 'TEXT', changeTextType('text')">Texto
                                  </button>
                                </b-card-header>
                              </b-card>
                              <b-card no-body>
                                <b-card-header header-tag="header" :class="{'active':form.type == 'FILE',}">
                                  <div class="icon-wrapper">
                                    <span class="material-symbols-outlined">photo</span>
                                  </div>
                                  <button class="btn btn-choose-type stretched-link" @click="form.type = 'FILE', changeTextType('media')">Arquivo</button>
                                </b-card-header>
                              </b-card>
                              <b-card no-body>
                                <b-card-header header-tag="header" :class="{'active':form.type == 'CAROUSELS',}">
                                  <div class="icon-wrapper">
                                    <span class="material-symbols-outlined">view_carousel</span>
                                  </div>
                                  <button class="btn btn-choose-type stretched-link" @click="form.type = 'CAROUSELS', changeTextType('carousel')">Carrossel</button>
                                </b-card-header>
                              </b-card>
                              <b-card no-body>
                                <b-card-header header-tag="header" :class="{'active':form.type == 'CARD',}">
                                  <div class="icon-wrapper">
                                    <span class="material-symbols-outlined">pages</span>
                                  </div>
                                  <button class="btn btn-choose-type stretched-link" @click="form.type = 'CARD', changeTextType('card')">Card</button>
                                </b-card-header>
                              </b-card>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">campaign</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <input type="text" class="form-control form-control-send" placeholder="Nome da campanha"
                              v-model="form.name">
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                            <span class="material-symbols-outlined info-icons">supervisor_account</span>
                          </div>
                          <div class="col-lg-10 col-sm-10 col-md-10 col-9">
                            <div class="row">
                              <div class="col-sm-7 relative tags-container" style="padding-right: 5px;"
                                v-if="selectedGroups.length == 0 && selectedContacts.length == 0">
                                <vue-tags-input v-model="form.to" :class="'form-control form-control-send'"
                                  :tags="form.tags" @tags-changed="(newTags) => (form.tags = newTags)"
                                  :placeholder="'Ex: (34) 44444-4444'" :separators="[',', ';', ' ']" :add-on-key="[13, ',', ';', ' ']" />
                                <span class="material-symbols-outlined span-close c-pointer close-input hide">close</span>
                              </div>
                              <div class="col-sm-7 no-margin tags-wrapper"
                                :class="{ 'hide': selectedGroups.length == 0 && selectedContacts.length == 0 }"
                                style="padding-right: 15px;">
                                <ul class="tabs-box">
                                  <li class="tab" v-for="(group, i) in selectedGroups" :key="i">
                                    <span>{{ group.name }}</span>
                                    <span class="material-symbols-outlined span-close"
                                      @click="removeGroup(i)">close</span>
                                  </li>
                                  <li class="tab" v-for="(contact, i) in selectedContacts" :key="i">
                                    <span>
                                      <template v-if="contact.mobile_number">
                                        {{ contact.mobile_number | maskPhone }}
                                      </template>
                                      <template v-if="contact.phone">
                                        {{ contact.phone | maskPhone }}
                                      </template>
                                    </span>
                                    <span class="material-symbols-outlined span-close"
                                      @click="removeContact(i)">close</span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-sm-4 no-margin no-padding icon-sends">
                                <span class="material-symbols-outlined span-close c-pointer"
                                  v-b-toggle.sidebar-right-contact v-tooltip.top="'Enviar por Contatos'">contact_page</span>
                                <span class="material-symbols-outlined span-close c-pointer"
                                  v-b-toggle.sidebar-right-group v-tooltip.top="'Enviar por Grupos'">group</span>
                                <span class="material-symbols-outlined span-close c-pointer"
                                  v-b-toggle.sidebar-right-import v-tooltip.top="'Enviar por Arquivo'">file_upload</span>
                                <span class="material-symbols-outlined span-close c-pointer add-circle hide"
                                  @click="addInput()">add_circle</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="divider-form"></div>
                        </div>
                        <div class="form-group contents-form" v-if="form.type == 'TEXT'">
                          <div class="row">
                            <div class="col">
                              <label for="sms-body"
                                >{{
                                  $t('sms.send-msg.sms-input-component.title')
                                }}:
                              </label>
                              <!-- Conteúdo -->
                            </div>
                            <div class="col text-right">
                              <b-dropdown
                                class="dropdown-badge mr-2"
                                v-if="variables.length"
                                variant="transparent p-0"
                              >
                                <template #button-content>
                                  <span class="badge btn-yup-purple">{{
                                    $t(
                                      'sms.send-msg.sms-input-component.lbl-variables',
                                    )
                                  }}</span>
                                </template>
                                <b-dd-item
                                  v-for="variable in variables"
                                  :key="variable.value"
                                  @click="addVariable(variable)"
                                >
                                  {{ variable.name }}
                                </b-dd-item>
                              </b-dropdown>
                              <Emojis
                                v-if="form.type === 'TEXT'"
                                @select="addEmoji"
                                title="Emoji"
                              />
                            </div>
                          </div>
                          <textarea
                            v-if="form.type === 'TEXT'"
                            class="form-control text-area-sms"
                            rows="6"
                            maxlength="612"
                            id="sms-body"
                            v-model="form.body.plain.text"
                          ></textarea>
                        </div>
                        <div class="form-group mb-4" v-if="form.type == 'FILE'">
                          <label>Adicionar arquivo: </label>
                          <p>Faça upload de arquivos nos diferentes formatod pdf, png, jpg, etc.</p>
                          <div class="content-upload relative" ref="dropz">
                            <div class="content-inside">
                              <span class="material-symbols-outlined">upload_file</span>
                              <h4>Adicionar arquivos pdf, png, jpg ou gif ou arraste e solte</h4>
                            </div>
                            <input
                              type="file"
                              ref="fileInput"
                              @change="onFilePicked"/>
                          </div>
                        </div>
                        <div class="form-group mb-4 relative" v-if="form.type == 'FILE'">
                          <input class="form-control" placeholder="Link" v-model="form.body.plain.url">
                          <span class="material-symbols-outlined link-input">link</span>
                        </div>
                        <!--Card-->
                        <div class="form-group row mb-4" v-if="form.type == 'CARD'">
                          <div class="col-12">
                            <label>Detalhe da mensagem: </label>
                            <br>
                            <div
                              class="shadow-card rounded card-rich"
                              style="width: 300px"
                            >
                              <div class="row">
                                <div class="col-12">
                                  <div class="card-rich-cover relative">
                                    <img
                                      :src="form.body.card.url"
                                      id="card-rich-cover-tab"
                                    />
                                    <ImageUploader
                                      @upload="
                                        (file) =>
                                          (form.body.card.url = file.url)
                                      "
                                    >
                                      <i
                                        class="fas fa-edit card-edit-btn absolute"
                                      ></i>
                                    </ImageUploader>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <label>Título</label>
                                  <input
                                    class="form-control"
                                    v-model="form.body.card.title"
                                  />
                                  <label>Descrição</label>
                                  <textarea
                                    class="form-control"
                                    style="width: 100%; float: left"
                                    v-model="form.body.card.description"
                                  ></textarea>
                                </div>
                              </div>
                              <div v-for="(button, n) in form.body.card.buttons" :key="n" class="row">
                                <hr v-if="n > 0" />
                                <div class="col-md-12">
                                  <div v-if="button.type === 'reply'" class="input-group mt-3">
                                    <input
                                      placeholder="Texto"
                                      v-model="button.text"
                                      type="text"
                                      class="form-control"
                                      @input="handleInput"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-primary btn-icon"
                                        type="button"
                                        @click="removeButton(form.body.card, n)"
                                      >
                                        <span class="material-icons edit_square_icon"> close </span>
                                      </button>
                                    </div>
                                  </div>
                                  <div v-if="button.type === 'url'" class="input-group mt-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-icon-addon1"
                                        ><i class="icon dripicons-web"></i
                                      ></span>
                                    </div>
                                    <input
                                      placeholder="Link"
                                      v-model="button.url"
                                      type="text"
                                      class="form-control"
                                      @input="handleInput"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-primary btn-icon"
                                        type="button"
                                        @click="removeButton(form.body.card, n)"
                                      >
                                        <span class="material-icons edit_square_icon"> close </span>
                                      </button>
                                    </div>
                                  </div>
                                  <div v-else-if="button.type === 'location'" class="input-group mt-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-icon-addon1"
                                        ><i class="icon dripicons-location"></i
                                      ></span>
                                    </div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Localização..."
                                      v-model="button.address"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-primary btn-icon"
                                        type="button"
                                        @click="removeButton(form.body.card, n)"
                                      >
                                        <span class="material-icons edit_square_icon"> close </span>
                                      </button>
                                    </div>
                                  </div>
                                  <div v-if="button.type === 'event'">
                                    <div class="input-group mt-3">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-icon-addon1"
                                          ><i class="icon dripicons-calendar"></i
                                        ></span>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Titulo do Evento"
                                        v-model="button.title"
                                      />
                                      <div class="input-group-append">
                                        <button
                                          class="btn btn-primary btn-icon"
                                          type="button"
                                          @click="removeButton(form.body.card, n)"
                                        >
                                          <span class="material-icons edit_square_icon"> close </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div class="input-group mt-3">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-icon-addon1"
                                          ><i class="icon dripicons-calendar"></i
                                        ></span>
                                      </div>
                                      <date-range-picker
                                        class="form-control"
                                        :format="'YYYY-MM-DD hh:mm'"
                                        :options="calendarOptions"
                                        @change="changeDate($event, button)"
                                      />
                                    </div>
                                  </div>
                                  <div v-show="button.type === 'dial'" class="input-group mt-3">
                                    <vue-tel-input
                                      v-model="button.phone_number"
                                      v-bind="phoneOptions"
                                    ></vue-tel-input>
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-primary btn-icon"
                                        type="button"
                                        @click="removeButton(form.body.card, n)"
                                      >
                                        <span class="material-icons edit_square_icon"> close </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 btn-actions">
                                  <button class="btn-add-action">
                                    <span class="material-symbols-outlined info-icons info-var-tip">flash_on</span> Adicionar ação
                                  </button>
                                  <button class="btn-add-action-hover">
                                    <span class="material-symbols-outlined" @click="addButton(form.body.card, 'dial')">phone</span>
                                    <span class="material-symbols-outlined" @click="addButton(form.body.card, 'reply')">text_fields</span>
                                    <span class="material-symbols-outlined" @click="addButton(form.body.card, 'url')">public</span>
                                    <span class="material-symbols-outlined" @click="addButton(form.body.card, 'location')">location_on</span>
                                    <span class="material-symbols-outlined" @click="addButton(form.body.card, 'event')">calendar_month</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--End Card-->
                        <!--CAROUSELS-->
                        <div class="form-group row mb-4" v-if="form.type == 'CAROUSELS'">
                          <div class="col-12">
                            <div class="content-message">
                              <label>Detalhe da mensagem: </label>
                              <button class="btn btn-add-template" @click="addCard">
                                <span class="material-symbols-outlined">add_circle</span><span class="span-text">Novo cartão</span>
                              </button>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col-5" v-for="(card, i) in form.body.carousel.cards" :key="i" >
                                <div
                                  class="shadow-card rounded card-rich"
                                  style="width: 300px"
                                >
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="card-rich-cover relative">
                                        <img
                                          :src="card.image"
                                          id="card-rich-cover-tab"
                                        />
                                        <ImageUploader
                                          @upload="
                                            (file) =>
                                              (card.image = file.url)
                                          "
                                        >
                                          <i
                                            class="fas fa-edit card-edit-btn absolute c-pointer"
                                          ></i>
                                        </ImageUploader>
                                        <span v-if="form.body.carousel.cards.length > 2" class="c-pointer material-symbols-outlined remove-card-icon" @click="removeCard(i)">
                                          close
                                        </span>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <label>Título</label>
                                      <input
                                        class="form-control"
                                        v-model="card.title"
                                      />
                                      <label>Descrição</label>
                                      <textarea
                                        class="form-control"
                                        style="width: 100%; float: left"
                                        v-model="card.description"
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div v-for="(button, n) in card.buttons" :key="n" class="row">
                                    <hr v-if="n > 0" />
                                    <div class="col-md-12">
                                      <div v-if="button.type === 'reply'" class="input-group mt-3">
                                        <input
                                          placeholder="Texto"
                                          v-model="button.text"
                                          type="text"
                                          class="form-control"
                                          @input="handleInput"
                                        />
                                        <div class="input-group-append">
                                          <button
                                            class="btn btn-primary btn-icon"
                                            type="button"
                                            @click="removeButton(card, n)"
                                          >
                                            <span class="material-icons edit_square_icon"> close </span>
                                          </button>
                                        </div>
                                      </div>
                                      <div v-if="button.type === 'url'" class="input-group mt-3">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text" id="basic-icon-addon1"
                                            ><i class="icon dripicons-web"></i
                                          ></span>
                                        </div>
                                        <input
                                          placeholder="Link"
                                          v-model="button.url"
                                          type="text"
                                          class="form-control"
                                          @input="handleInput"
                                        />
                                        <div class="input-group-append">
                                          <button
                                            class="btn btn-primary btn-icon"
                                            type="button"
                                            @click="removeButton(card, n)"
                                          >
                                            <span class="material-icons edit_square_icon"> close </span>
                                          </button>
                                        </div>
                                      </div>
                                      <div v-else-if="button.type === 'location'" class="input-group mt-3">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text" id="basic-icon-addon1"
                                            ><i class="icon dripicons-location"></i
                                          ></span>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Localização..."
                                          v-model="button.address"
                                        />
                                        <div class="input-group-append">
                                          <button
                                            class="btn btn-primary btn-icon"
                                            type="button"
                                            @click="removeButton(card, n)"
                                          >
                                            <span class="material-icons edit_square_icon"> close </span>
                                          </button>
                                        </div>
                                      </div>
                                      <div v-if="button.type === 'event'">
                                        <div class="input-group mt-3">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-icon-addon1"
                                              ><i class="icon dripicons-calendar"></i
                                            ></span>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Titulo do Evento"
                                            v-model="button.title"
                                          />
                                          <div class="input-group-append">
                                            <button
                                              class="btn btn-primary btn-icon"
                                              type="button"
                                              @click="removeButton(card, n)"
                                            >
                                              <span class="material-icons edit_square_icon"> close </span>
                                            </button>
                                          </div>
                                        </div>
                                        <div class="input-group mt-3">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-icon-addon1"
                                              ><i class="icon dripicons-calendar"></i
                                            ></span>
                                          </div>
                                          <date-range-picker
                                            class="form-control"
                                            :format="'YYYY-MM-DD hh:mm'"
                                            :options="calendarOptions"
                                            @change="changeDate($event, button)"
                                          />
                                        </div>
                                      </div>
                                      <div v-show="button.type === 'dial'" class="input-group mt-3">
                                        <vue-tel-input
                                          v-model="button.phone_number"
                                          v-bind="phoneOptions"
                                        ></vue-tel-input>
                                        <div class="input-group-append">
                                          <button
                                            class="btn btn-primary btn-icon"
                                            type="button"
                                            @click="removeButton(card, n)"
                                          >
                                            <span class="material-icons edit_square_icon"> close </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 btn-actions">
                                      <button class="btn-add-action">
                                        <span class="material-symbols-outlined info-icons info-var-tip">flash_on</span> Adicionar ação
                                      </button>
                                      <button class="btn-add-action-hover">
                                        <span class="material-symbols-outlined" @click="addButton(card, 'dial')">phone</span>
                                        <span class="material-symbols-outlined" @click="addButton(card, 'reply')">text_fields</span>
                                        <span class="material-symbols-outlined" @click="addButton(card, 'url')">public</span>
                                        <span class="material-symbols-outlined" @click="addButton(card, 'location')">location_on</span>
                                        <span class="material-symbols-outlined" @click="addButton(card, 'event')">calendar_month</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" style="margin-bottom: 0;">
                          <div class="col-lg-1 col-sm-1 col-md-1 col-2">
                          </div>
                        </div>
                        <div class="form-group row text-right">
                          <div class="col-sm-12">
                            <div>
                              <div class="right-items">
                                <button class="send-button send-button-outline hide">Enviar teste</button>
                                <y-date-picker :btnLabel="'Agendar envio'" @change="changeSchedule" :type="'button'" :iconClass="'calendarIcon'" :options="optionCalendar" :format="'DD/MM/YYYY HH:mm'"></y-date-picker>
                                <button class="btn send-button" type="button" @click="sendRequest" :class="{
                                  'qt-loader qt-loader-mini qt-loader-right': isSending
                                }" :disabled="isSending || importing" >Enviar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/Left column -->
        <div class="col-lg">
          <div class="preview-div">
            <div class="preview-wrapper">
              <TemplatePreview :template="form"/>
              <img
                class="phone-img"
                src="@/assets/phone.svg"
                alt="Phone Background"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-import" :title="'Importar'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Importar contatos</p>
        <span v-b-toggle.sidebar-right-import class="material-symbols-outlined sidebar-close c-pointer">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Faça upload de arquivos csv para importar contatos
        </p>
      </div>
      <form class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="drop-item-upload">
            <img src="@/assets/csv_import.svg" v-if="!importing" />
            <div id="cont" data-pct="100" :class="{ 'hide': !importing }">
              <svg id="svg" width="200" height="200" viewPort="0 0 100 100" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <circle r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0">
                </circle>
                <circle id="bar" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48"
                  stroke-dashoffset="0"></circle>
              </svg>
            </div>
            <h3>Clique ou arraste seu cvs aqui</h3>
            <button type="button" @click="openFileUpload">Selecionar Arquivos do seu dispositivo</button>
            <input ref="uploadedFile" type="file" accept=".csv" hidden @change="uploadFile" />
          </div>
          <div class="row csv-file" v-if="form.file">
            <div class="icon-div">
              <img src="assets/img/csv_icon.svg">
            </div>
            <div class="name-csv-div">
              <span>{{ form.file }}</span>
              <div class="csv-progress"></div>
            </div>
            <div class="name-csv-percent">100%</div>
          </div>
          <p class="drop-item-upload-p">
            Alguns formatos de dados, como nomes e números podem não ser reconhecidos. ler mais
          </p>
          <div>
            <a
              class="sample-area d-flex align-items-center"
              href="/exemplos/example.csv"
              download="example.csv"
              v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')">
                <!-- {{$t('generic-str.example')}} -->
                <span class="material-icons-outlined" style="margin-right: 5px;">file_download</span>&nbsp;&nbsp;Baixar arquivo de exemplo
            </a>
          </div>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-numbers" :title="'Números'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Agentes</p>
        <span v-b-toggle.sidebar-right-numbers class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
      </div>
      <form class="add-prod-form col mb-0">
        <div class="input-group">
          <div class="text-center py-5 w100" v-if="!fetchedAgents">
            <img src="assets/img/loading.gif">
          </div>
          <div v-if="agents" class="w100">
            <div class="row row-list" v-for="(agent, i) in agents" :key="agent.id">
              <div class="col-sm-6 text-left">
                <span class="contact-name contact-phone">
                  {{ agent.name }}
                </span>
              </div>
              <div class="col-sm-1">
                <div>
                  <input v-b-toggle.sidebar-right-numbers v-model="form.agentId" type="radio" :value="agent.id" :id="'contact-check-' + i" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-group" :title="'Grupos'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Grupos</p>
        <span v-b-toggle.sidebar-right-group class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Simplifique suas campanhas com nossa ferramenta de seleção de grupos de contatos. Alcance o público certo, no
          momento certo, com facilidade e eficiência, garantindo o sucesso de suas campanhas de marketing.
        </p>
      </div>
      <form @submit.prevent class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input @keydown.enter="searchGroup" placeholder="Buscar" class="form-control" ref="searchInput" />
            <span class="material-symbols-outlined c-pointer" @click="searchGroup">search</span>
          </div>
        </div>
        <router-link to="/contacts/groups">
          <div class="input-group">
            <div class="header-options">
              <div class="header-options-title" style="width: 55%;">
                <span>Meus grupos</span>
              </div>
              <div class="header-options-buttons c-pointer">
                <span class="material-symbols-outlined">settings</span>
                <h4>Gerenciar grupos</h4>
              </div>
            </div>
          </div>
        </router-link>
        <div class="input-group">
          <div class="text-center py-5 w100" v-if="!fetchedGroups">
            <img src="assets/img/loading.gif">
          </div>
          <div v-if="fetchedGroups" class="w100">
            <div class="row row-list" v-for="(group, i) in groups" :key="group.id">
              <div class="col-sm-1">
                <div class="custom-control custom-checkbox">
                  <input v-model="groupsToAdd" type="checkbox" class="custom-control-input" :value="group"
                    :id="'group-check-' + i" />
                  <label class="custom-control-label" :for="'group-check-' + i"></label>
                </div>
              </div>
              <div class="col-sm-10">
                <div class="flex">
                  <span class="material-symbols-outlined">group</span>
                  <span>{{ group.name }}</span>
                </div>
              </div>
              <div class="col-sm-1">

              </div>
            </div>
            <div class="row">
              <pagination v-model="currentPageGroup" :total-pages="pagesGroup" @change="getGroups" />
            </div>
          </div>
        </div>
        <div class="input-group text-right flex-c modal-footer">
          <button type="button" v-b-toggle.sidebar-right-group class="btn btn-yup btn-add align-r"
            @click="applyGroups()">Aplicar</button>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-contact" :title="'Contatos'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Contatos</p>
        <span v-b-toggle.sidebar-right-contact class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Simplifique suas campanhas com nossa ferramenta de seleção de contatos. Alcance o público certo, no momento
          certo, com facilidade e eficiência, garantindo o sucesso de suas campanhas de marketing.
        </p>
      </div>
      <form @submit.prevent class="add-prod-form col mb-0">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input placeholder="Buscar" class="form-control" v-model="formContact.q" @keydown.enter="searchContacts" />
            <span class="material-symbols-outlined c-pointer" @click="searchContacts"
              ref="searchInputContact">search</span>
          </div>
        </div>
        <div class="input-group">
          <div class="text-center py-5 w100" v-if="!fetchedContacts">
            <img src="assets/img/loading.gif">
          </div>
          <div v-if="fetchedContacts" class="w100">
            <div class="row row-list" v-for="(contact, i) in contacts" :key="contact.id">
              <div class="col-sm-1">
                <div class="custom-control custom-checkbox" style="margin-top: -9px;">
                  <input v-model="contactsToAdd" type="checkbox" class="custom-control-input" :value="contact"
                    :id="'contact-check-' + i" />
                  <label class="custom-control-label" :for="'contact-check-' + i"></label>
                </div>
              </div>
              <div class="col-sm-7" style="padding-left: 0px;">
                <div class="flex">
                  <div class="profile-photo">
                    <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }">
                      <div class="fallback-image">
                        {{ initials(contact.first_name, contact.last_name) }}
                      </div>
                    </figure>
                  </div>
                  <span class="contact-name">
                    {{ contact.first_name }}
                  </span>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="contact-name contact-phone">
                  <template v-if="contact.mobile_number">
                    {{ contact.mobile_number | maskPhone }}
                  </template>
                  <template v-if="contact.phone">
                    {{ contact.phone | maskPhone }}
                  </template>
                </span>
              </div>
            </div>
            <div class="row">
              <pagination v-model="currentPage" :total-pages="pages" @change="getContacts" />
            </div>
          </div>
        </div>
        <div class="input-group text-right flex-c modal-footer">
          <button type="button" v-b-toggle.sidebar-right-contact class="btn btn-yup btn-add align-r"
            @click="applyContacts()">Aplicar</button>
        </div>
      </form>
    </b-sidebar>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-template" :title="'Templates'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <p>Templates</p>
        <span v-b-toggle.sidebar-right-template class="material-symbols-outlined sidebar-close">close</span>
      </div>
      <div class="col desc-column">
        <p>
          Simplifique suas campanhas com templates exclusivos. Alcance o público certo, no momento certo, com facilidade e
          eficiência, garantindo o sucesso de suas campanhas de marketing.
        </p>
      </div>
      <form class="add-prod-form col mb-0" @submit.prevent="fetchTemplates(1)">
        <!-- Name -->
        <div class="input-group">
          <div class="search-input relative">
            <input placeholder="Buscar" class="form-control" ref="searchTemplatesInput" v-model="searchQuery" />
            <span class="material-symbols-outlined c-pointer" @click="fetchTemplates(1)">search</span>
          </div>
        </div>
        <div class="input-group">
          <div class="header-options">
            <div class="header-options-title" style="width: 50%;">
            </div>
            <div class="header-options-buttons header-options-buttons-template">
              <h4 style="color: #697077;">Ordenar por (padrão) </h4>
              <svg width="24" height="24" class="c-pointer" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M17 20L21 16M3 4H16H3ZM3 8H12H3ZM3 12H12H3ZM17 8V20V8ZM17 20L13 16L17 20Z" stroke="#697077"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div class="text-center py-5 w100" v-if="!fetchedTemplates">
          <img src="assets/img/loading.gif">
        </div>
        <div v-else>
          <div class="input-group" v-for="(templ, i) in templates" :key="templ.id">
            <div class="row row-list row-template-list">
              <div class="col-sm-1">
                <input v-model="form.template" type="radio" :value="templ" v-b-toggle.sidebar-right-template
                  @change="changeTemplate(templ)" :id="'contact-template-check-' + i" />
              </div>
              <div class="col-sm-9">
                <div class="flex">
                  <span>{{ templ.name }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <span class="material-symbols-outlined icon-show-hide c-pointer" @click="showTemplate(templ)"
                  v-if="!templ.show">visibility_off</span>
                <span class="material-symbols-outlined icon-show-hide c-pointer" @click="hideTemplate(templ)"
                  v-if="templ.show">visibility</span>
              </div>
            </div>
            <div class="row row-templates" v-if="templ.show" style="margin-bottom: 10px;">
              <div class="col-sm-12">
                <div class="card-template">
                  <div class="template-content relative">
                    <TemplatePreview :template="templ"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <pagination v-model="templatePage" :total-pages="templatePages" @change="fetchTemplates" />
      </form>
    </b-sidebar>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
    <m-crop id="m-crop" />
  </Page>
</template>

<script>
// @ is an alias to /src
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from '@/plugins/axios';
import RCSService from '@/services/rcs.service';
import Tab from '@/directives/Tab';
import Page from '@/components/Page.vue';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/rebranding/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/rebranding/contacts/ContactSendModal.vue';
import ContactService from '@/services/contact.service';
import VueTagsInput from '@johmun/vue-tags-input';
// import GroupTable from '@/components/groups/GroupTable.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import YDatePicker from '@/components/DatePicker/YDatePicker.vue';
import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import TemplatePreview from '@/components/rcs/TemplatePreview.vue';
import Emojis from '@/components/rebranding/Emojis.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import { VueTelInput } from 'vue-tel-input';

const bucketApi = axios('buckets3');

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  name: 'RcsSend',
  components: {
    Page,
    PageHeader,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    MCrop,
    VueTagsInput,
    YDatePicker,
    Pagination,
    TemplatePreview,
    Emojis,
    ImageUploader,
    VueTelInput,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      contactsToAdd: [],
      groupsToAdd: [],
      currentPage: 1,
      currentPageGroup: 1,
      currentPageContact: 1,
      templatePrev: {},
      page: 1,
      formGroup: {},
      templatePage: 1,
      formContact: {},
      searchQuery: null,
      templatePages: 1,
      optionCalendar: {
        timePicker: true,
        singleDatePicker: true,
        autoApply: true,
        startDate: moment().format('DD/MM/YYYY'),
        drops: 'up',
        timePicker24Hour: true,
      },
      agents: [],
      fetchedAgents: false,
      selectedAgent: {},
      fetchedGroups: false,
      fetchedContacts: false,
      fetchedTemplates: false,
      variables: [],
      infos: null,
      show: false,
      rcs: {
        text: {
          content: '',
        },
        media: {},
        calendar: {
          messageText: '',
          suggestions: [
            {
              action: {
                textEdit: false,
                text: this.$t('options-div.btn-text'),
                postbackData: this.$t('options-div.lbl-response'),
                createCalendarEventAction: {
                  nstartTime: '2020-06-30T19:00:00Z',
                  nendTime: '2020-06-30T20:00:00Z',
                  title: this.$t('options-div.event-title'),
                  titleEdit: false,
                  description: this.$t('options-div.event-description'),
                  descriptionEdit: false,
                },
              },
            },
          ],
        },
        links: {
          messageText: '',
          suggestions: [
            {
              action: {
                text: this.$t('generic-str.action'),
                postbackData: 'Resposta 1',
                openUrlAction: {
                  url: 'https://www.google.com',
                },
              },
            },
          ],
        },
        richCard: {
          titleEdit: false,
          messageEdit: false,
          messageText: this.$t('options-div.card-title'),
          messageDescription: this.$t('options-div.card-text'),
          imageUrl:
            'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png', // '/assets/img/default_card_bg.jpeg',
          height: 'TALL',
          suggestions: [
            {
              reply: {
                text: this.$t('generic-str.action'),
                postbackData: 'Ok',
              },
            },
          ],
        },
        richCarousels: {
          images: [
            {
              titleEdit: false,
              messageEdit: false,
              title: this.$t('options-div.card-title'),
              description: this.$t('options-div.card-text'),
              uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
              height: 'MEDIUM',
              postbackData: 'Ok',
              suggestions: [
                {
                  action: {
                    text: 'Link',
                    postbackData: 'Resposta 1',
                    openUrlAction: {
                      url: 'https://www.google.com',
                    },
                  },
                },
              ],
            },
            {
              titleEdit: false,
              messageEdit: false,
              title: this.$t('options-div.card-title'),
              description: this.$t('options-div.card-text'),
              uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
              height: 'MEDIUM',
              postbackData: 'Ok',
              suggestions: [
                {
                  action: {
                    text: 'Link',
                    postbackData: 'Resposta 1',
                    openUrlAction: {
                      url: 'https://www.google.com',
                    },
                  },
                },
              ],
            },
          ],
        },
      },
      tabs: 'text',
      cropActive: {},
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      vars: [],
      tags: [],
      value: null,
      fileVariables: [],
      files: {
        richCard: {},
        richCarousels: [],
      },
      indexFile: 0,
      form: {
        type: 'TEXT',
        agentId: '',
        from: 'Default',
        sender: 'Default',
        pub_sub: true,
        contacts: [],
        groups: [],
        body: {
          plain: {
            text: 'Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o dia xxx.',
            url: '',
          },
          card: {
            title: '',
            description: '',
            orientation: 'VERTICAL',
            url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
            size: 'TALL',
            alignment: 'LEFT',
            buttons: [],
          },
          carousel: {
            cards: [
              {
                title: '',
                description: '',
                image:
                  'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                size: 'TALL',
                buttons: [],
              },
              {
                title: '',
                description: '',
                image:
                  'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                size: 'TALL',
                buttons: [],
              },
            ],
          },
        },
        scheduledAt: null,
        file: '',
        normalize: false,
        flash: false,
        template: null,
        schedule: true,
        messageType: 'text',
        tags: [],
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      submited: false,
      length: 0,
      uploadProgress: 0,
      features: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store?.state?.auth?.user;
    },
    locked() {
      if (this.$store.state.account.plans.rcs == null) {
        this.fetchAgents();
        return true;
      }
      return false;
      // return this.submited;
    },
  },
  created() {
    if (!this.locked) {
      this.fetchAgents();
      this.getGroups(1);
      this.getContacts(1);
      this.fetchTemplates(1);
      if (this.$route.params.contacts) {
        this.contacts = this.$route.params.contacts;
        this.contacts.forEach((contact) => {
          this.selectedContacts.push(contact.id);
        });
        this.form.type = 'contacts';
        this.changeType('contacts');
      }
      if (this.$route.params.groups) {
        this.groups = this.$route.params.groups;
        this.groups.forEach((group) => {
          this.selectedGroups.push(group.id);
        });
        this.form.type = 'groups';
        this.changeType('groups');
      }
      this.$forceUpdate();
    }
  },
  methods: {
    addButton(card, type) {
      card.buttons.push({
        text: 'text',
        type,
        start_time: moment().startOf('day').format(),
        end_time: moment().endOf('day').format(),
      });
      this.$emit('input', this.buttons);
    },
    removeButton(card, i) {
      card.buttons.splice(i, 1);
    },
    changeDate(date, button) {
      if (date) {
        button.start_time = moment(date[0]).startOf('day').format();
        button.end_time = moment(date[1]).endOf('day').format();
      }
    },
    onFilePicked(event) {
      const files = event.target.files;
      const filename = files[0].name;
      this.file = files[0];

      if (document.querySelector('.drop-img')) {
        document.querySelector('.drop-img').remove();
      }
      if (document.querySelector('.content-inside')) {
        document.querySelector('.content-inside').remove();
      }
      const dropImg = document.createElement('div');
      dropImg.classList.add('drop-img');
      dropImg.classList.add('file-unic');

      const span = document.createElement('span');
      span.classList.add('material-icons-outlined');

      if (files[0].type.includes('image')) {
        span.innerHTML = 'image';
      } else if (files[0].type.includes('video')) {
        span.innerHTML = 'play_circle';
      } else if (files[0].type.includes('audio')) {
        span.innerHTML = 'music_note';
      } else {
        span.innerHTML = 'content_copy';
      }

      const h3 = document.createElement('h3');
      h3.classList.add('h3-type');
      h3.innerHTML = files[0].type;

      dropImg.append(span);
      dropImg.append(h3);
      this.$refs.dropz.append(dropImg);
      this.uploadFile();
    },
    uploadFile() {
      this.isSending = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('account_id', this.account.id);
      formData.append('user_id', this.user.id);
      const config = {
        headers: { 'Content-Type': 'multipart/form-data', 'X-API-SECRET': 'Odyg1JD2UgJKsCojwwDppA7PdYSasIAY' },
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };
      const result = bucketApi.post('', formData, config).then((response) => {
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: 'Upload realizado com sucesso',
          type: 'success',
        });
        this.progress = 0;
        this.form.body.plain.url = response.data.path;
      },
      (error) => {
        this.content = error;
        this.progress = 0;
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: error.response.data.message,
          type: 'danger',
        });
      });
      this.isSending = false;
    },
    addEmoji(emoji) {
      this.form.body.plain.text = `${this.form.body.plain.text}${emoji}`;
    },
    changeSchedule(date) {
      this.form.schedule = true;
      this.form.scheduledAt = date;
      this.$forceUpdate();
    },
    showTemplate(template) {
      template.show = true;
      template.body = JSON.parse(template.body);
      this.$forceUpdate();
    },
    changeTemplate(template) {
      this.form.type = template.type;
      this.form.messageType = template.type.toLowerCase();
      if (this.form.messageType === 'carousels') {
        this.form.messageType = 'carousel';
      }
      if (this.form.messageType === 'file') {
        this.form.messageType = 'media';
      }
      this.form.template = template;
      this.form.body = JSON.parse(template.body);
      console.log('this.form.messageType', this.form.messageType);
      this.$forceUpdate();
    },
    fetchTemplates(page) {
      this.fetchedTemplates = false;
      this.templatePage = page;
      this.templates = [];
      RCSService.getTemplates({
        filter: { name: this.searchQuery, account_id: this.account.id }, page,
      }).then((response) => {
        console.log('Templates');
        console.log(response);
        this.fetched = true;
        if (response && response.data) {
          // response.data.content.forEach((template) => {
          //   if (template.status !== 'DELETED') {
          //     this.templates.push(template);
          //   }
          // });
          this.templates = response.data.data;
          console.log('Search temp: ', this.templates);
        }
        this.pagesTemplate = response.data.last_page || 0;
        this.fetchedTemplates = true;
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedTemplates = true;
      });
    },
    subscribeRCS() {
      this.$router.push('/channels');
    },
    fetchAgents() {
      this.$store
        .dispatch('fetchRcsAgents')
        .then((response) => {
          this.agents = response;
          this.form.agentId = this.agents[0].id;
          this.form.agent_name = this.agents[0].name;
          this.fetchedAgents = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeAgent(agent) {
      this.selectedAgent = agent;
    },
    crop(data) {
      this.cropActive.dataCrop = data;
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        // this.form.body.card.url = data.url;
        if (this.tabs === 'card') {
          this.files[data.name] = data;
          document.getElementById(this.cropPreview).src = `${
            this.files[data.name].url
          }`;
        }
        if (this.tabs === 'card-carousel') {
          this.files[data.name][this.indexFile] = data;
          document.getElementById(this.cropPreview).src = `${
            this.files[data.name][this.indexFile].url
          }`;
          this.files[data.name][this.indexFile].index = this.indexFile;
        }
      }
      console.log(this.files);
    },
    callCrop(
      cropPreview,
      maxWidthP,
      maxHeightP,
      cropActive,
      nameP = null,
      index = 0,
    ) {
      this.$crop.show({
        width: 1700,
        height: 800,
      });
      /* this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.indexFile = index;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        name: nameP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      }); */
    },
    setValue(value) {
      this.$emit('input', value);
    },
    addVariable(variable) {
      this.rcs.text.content = `${this.rcs.text.content}{${variable.value}}`;
    },
    changeTextTab(tab) {
      // this.tabs = tab;
      this.form.messageType = tab;
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.form.groups.includes(group.id)) {
          this.groups.push(group);
          this.form.groups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.form.contacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.form.contacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeTextType(type) {
      this.form.messageType = type;
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups' || type === 'files') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          {
            name: this.$t('generic-str.lbl-cellphone'),
            value: 'mobile_number',
          },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.form.groups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.form.contacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    countSegments(newValues) {
      console.log(newValues);
      this.length = this.form.body.length + newValues;
    },
    send() {
      this.isSending = true;
      this.$store
        .dispatch('storeRcsMessages', this.form)
        .then((response) => {
          if (this.form.type === 'simple') {
            this.$router.push({
              name: 'RCSSuccess',
              params: { rcs: response.data },
            });
          } else {
            this.$router.push('/rcs/reports/campaign');
          }
        })
        .catch((ex) => {
          ex.response.data.errors.forEach((error) => {
            this.$toast.show({
              title: `Error ${error.code}`,
              content: error.detail,
              type: 'danger',
            });
          });
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    sendRequest() {
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.isSending = true;
          this.form.rcs = this.rcs;
          this.sendMessage();
        }
      });
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    addCardSuggestion() {
      this.form.body.card.suggestions.push({
        postback_data: 'post',
        text: 'text',
        type: 'reply',
      });
    },
    removeCardSuggestion(i) {
      this.form.body.card.suggestions.splice(i, 1);
    },
    addCard() {
      this.form.body.carousel.cards.push({
        title: '',
        description: '',
        image:
          'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
        size: 'TALL',
        buttons: [],
      });
    },
    removeCard(i) {
      this.form.body.carousel.cards.splice(i, 1);
    },
    sendMessage() {
      RCSService.send(this.form)
        .then(
          (send) => {
            if (this.form.type === 'simple') {
              this.$router.push({
                name: 'RCSSuccess',
                params: { sms: send.data },
              });
            } else {
              this.$router.push('/rcs/reports/campaign');
            }
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-registered'),
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    searchContacts() {
      this.fetchedContacts = false;
      const word = this.$refs.searchInputContact.value;
      this.formContact.page = 1;
      ContactService.searchContacts(this.formContact, word).then(
        (response) => {
          this.fetchedContacts = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedContacts = true;
        },
      );
    },
    searchGroup() {
      this.fetchedGroups = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      this.formGroup.page = 1;
      ContactService.getGroups(this.formGroup).then(
        (response) => {
          this.fetchedGroups = true;
          this.groups = response.data;
          result = this.groups.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));

          this.groups = result;
          this.pagesGroup = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedGroups = true;
        },
      );
    },
    getContacts(page) {
      this.formContact.page = page;
      this.currentPage = page;
      this.fetchedContacts = false;
      ContactService.getContacts(this.formContact).then(
        (response) => {
          this.fetchedContacts = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          this.fetchedContacts = true;
        },
      );
    },
    applyGroups() {
      this.form.contacts = [];
      this.groupsToAdd.forEach((group) => {
        if (!this.selectedGroups.includes(group)) {
          this.form.tags.push({
            text: group.name,
            id: group.id,
            type: 'group',
          });
        }
      });
      this.groupsToAdd = [];
      this.dragEvent();
    },
    applyContacts() {
      this.form.contacts = [];
      this.contactsToAdd.forEach((contact) => {
        if (!this.selectedContacts.includes(contact)) {
          this.form.tags.push({
            text: contact.mobile_number,
            id: contact.id,
            type: 'contact',
          });
        }
      });
      this.contactsToAdd = [];
      this.dragEvent();
    },
    dragEvent() {
      /* eslint-disable */
      const tabsBox = document.querySelector('.tabs-box');
      if (tabsBox) {
        let isDragging = false;
        const dragging = (e) => {
          // console.log('dragging');
          if (!isDragging) return;
          tabsBox.classList.add('dragging');
          tabsBox.scrollLeft -= e.movementX;
        };

        const dragStop = () => {
          isDragging = false;
          tabsBox.classList.remove('dragging');
        }

        tabsBox.addEventListener('mousedown', () => isDragging = true);
        tabsBox.addEventListener('mousemove', dragging);
        document.addEventListener('mouseup', dragStop);
      }
    },
    getGroups(page) {
      this.formGroup.page = page;
      this.currentPageGroup = page;
      this.fetchedGroups = false;
      ContactService.getGroups(this.formGroup).then(
        (response) => {
          this.fetchedGroups = true;
          this.groups = response.data;
          this.pagesGroup = response.last_page;
        },
        (error) => {
          this.fetchedGroups = true;
          this.content = error;
        },
      );
    },
    initials(firstname, lastname) {
      (firstname === null ? firstname = '' : firstname); // Avoiding displaying 'null' string
      (lastname === null ? lastname = '' : lastname); // Avoiding displaying 'null' string
      const emojiRegex = /\p{Emoji}/u;
      console.log('Firstname: ', firstname, '\nLastname: ', lastname);
      let fullname = '';
      if (firstname) {
        firstname = !firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      if (lastname) {
        lastname = !lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      fullname = firstname + lastname;

      return fullname;
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
    this.$root.$on('call.crop', this.crop);
  },
};
</script>

<style scoped lang="scss">
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

textarea:focus+.textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.input-date {
  margin-top: 15px !important;
}

.form-control.invalid {
  border-color: #ff5c75;
}

.actions-var {
  position: relative !important;
}

.emoji-color {
  color: #a6a6a6 !important;
}

.send-group-card {
  box-shadow: none;
}

.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}

.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}

.actions-icon {
  font-size: 14px !important;
}

.actions-var a {
  cursor: pointer;
}

.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}

.ul-list-groups {
  margin: 0px;
  padding: 0px;
}

.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.var-insede-input {
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.var-insede-input i {
  font-size: 15px !important;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

/* .send-opt li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
} */

.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

.right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.recipient-wrapper,
#recipients-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#recipients-list li {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
}

.darkmode #recipients-list li {
  border-color: var(--background-3);
  background-color: var(--background-2);
}

#recipients-list li .delete-item {
  font-size: 0.7rem;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

#recipients-list li .delete-item:hover {
  background-color: rgba(210, 221, 234, 0.5);
}

.darkmode #recipients-list li .delete-item:hover {
  background-color: var(--background-3);
}

/* .dropup::v-deep ul.dropdown-menu.show {
  top: -180% !important;
} */

.dropup::v-deep ul.dropdown-menu {
  width: fit-content;
}

.dropup::v-deep ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.template-wrapper label {
  padding-right: 3rem;
}

.form-floating.floating-transparent textarea {
  border-color: transparent !important;
  /* border-bottom: 2px solid #00000029 !important; */
  padding-left: 0;
  margin-left: 0.8rem;
}

.template-style {
  font-size: 0.8rem;
}

.v-select::v-deep>div:first-child,
.campaign-input,
.form-file {
  min-width: 320px;
  max-width: 320px;
}

.sender-group,
.template-wrapper {
  margin-bottom: 1.5rem;
}

.campaign-group {
  margin-bottom: 3rem;
}

::v-deep label {
  color: var(--gray-font-color-2);
  font-weight: 400;
}

::v-deep .vs__search {
  position: absolute !important;
}

.add-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn_add {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: auto;
}

hr {
  width: 100%;
  border-top: 2px solid #00000029;
}

.btn-import {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: 1.25rem;
}

.btn-import span {
  color: var(--gray-font-color);
  font-weight: 300;
}

.archive-opt {}

.darkmode .form-floating.floating-transparent textarea {
  background-color: var(--background-2);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}

.calendar_form {
  position: absolute;
  right: 150px !important;
  width: 150px !important;

  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 25px;
}

.card {
  border: 1px solid #E5E5E5;
  box-shadow: none;
}

.card-header {
  padding: 10px 18px !important;
  background: #F2F2F2;
  border-radius: 8px 8px var(8px, 0px) var(8px, 0px);
}

.card-hours-span {
  color: #AAA;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 72%;
}

.card-header-right {
  display: flex;
  width: 25%;
  flex-direction: row;
  cursor: pointer;
}

.card-header-right:hover span {
  color: #312b2b;
}

.card-header-right:hover #light-svg {
  fill: #312b2b;
}

.card-header-right span {
  color: #595959;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  padding-top: 2px;
  margin-left: 5px;
}

.info-icons {
  color: #AAAAAA;
  font-size: 24px;
  margin-top: 5px;
  margin-left: 15px;
}

.form-control-send {
  padding: 10px 8px !important;
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
}

.list-numbers {
  list-style: none;
  margin: 0px !important;
  padding: 0px !important;
}

.list-numbers li {
  color: #595959;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding: 4px 10px;
  border: 2px solid #E5E5E5;
  float: left;
  margin-right: 24px;
  width: 41%;
}

.list-numbers li div .span-close {
  font-size: 15px;
  cursor: pointer;
  display: inline-block;
}

.no-margin {
  margin: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.icon-sends {
  padding-left: 10px !important;
}

.icon-sends span {
  margin-top: 4px;
  color: #697077;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}

.icon-sends span:hover {
  color: #3e4042;
}

.add-circle {
  color: #6B23DC !important;
}

.divider-form {
  width: 100%;
  height: 4px;
  background: rgba(107, 35, 220, 0.24);
  margin-left: 10px;
  margin-right: 10px;
}

.text-right {
  text-align: right !important;
}

.send-button {
  padding: 6px 24px;
  border-radius: 8px;
  background: #6B23DC;
  color: #fff;
  border: none;
  margin-top: 15px;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  margin-left: 22px;
}

.send-button-outline {
  background: #fff;
  color: #6B23DC;
  border: 1px solid #6B23DC;
}

.send-button:hover {
  background: #732be6;
}

.send-button-outline:hover {
  background: #e4d8f7;
}

::v-deep #sidebar-right-group {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

::v-deep #sidebar-right-contact {
  background-color: var(--background-2) !important;
  width: 625px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  background-color: #6B23DC;
  color: #FFF;
  font-weight: 400;
  border-radius: 8px;
  font-size: 1rem;
}

.search-input {
  width: 100%;
  margin-bottom: 22px;
}

.search-input input {
  height: 50px;
}

.search-input span {
  position: absolute;
  top: 12px;
  right: 10px;
}

.search-input span:hover {
  color: #ccc;
}

.header-options {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 22px;
}

.header-options-title {
  width: 30%;
}

.header-options-title span {
  color: #0D0519;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.header-options-buttons {
  display: flex;
}

.header-options-buttons span {
  color: #6B23DC;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 4px;
  background: rgba(107, 35, 220, 0.08);
  border-radius: 8px;
  margin-right: 16px;
}

.header-options-buttons h4 {
  color: #6B23DC;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
}

.row-list {
  display: flex;
  width: 100%;
  margin: 0px;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  margin-bottom: 10px;
}

.row-list .material-symbols-outlined {
  font-size: 21px;
  margin-right: 8px;
}

.row-list span {
  color: #697077;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.flex {
  display: flex !important;
}

.modal-footer {
  margin-top: 40px;
  margin-bottom: 40px;
}

::v-deep .b-sidebar-modal .b-sidebar {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.contact-name {
  margin-top: 3px;
}

.contact-phone {
  margin-top: 5px;
  display: inline-block;
}

.contact-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

::v-deep #sidebar-right-template {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.header-options-buttons-template {
  color: #697077 !important;
  width: 54% !important;
}

.header-options-buttons-template h4 {
  margin-right: 16px !important;
}

.preview-wrapper {
  /* position: sticky;
  top: 100px; */
  display: flex;
  align-items: center;
  height: 472px;
}

.card-preview {
  width: 100%;
}

.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;

  .btn {
    &:nth-child(1) {
      float: left;
      width: 48%;
    }

    &:nth-child(2) {
      float: right;
      width: 48%;
    }

    &.max {
      width: 100%;
    }
  }
}

.preview-message-btns i {
  color: #00a5f4;
}

.preview-message-btns span {
  display: flex;
  margin-bottom: 8px;
  justify-content: center;
  gap: 1rem;
  margin-top: .725rem;
}

.c-pointer {
  cursor: pointer !important;
}

.media {
  video {
    max-height: 220px;
  }
}

.img-wrapper {
  display: flex;
  justify-content: center;
}

.card-options {
  position: absolute;
  top: -70px;
  right: 0;
  width: 330.633px;
  // min-width: 330.633px;
  // max-width: 330.633px;
  // min-height: 200px;
  background-color: #00000038;
  backdrop-filter: blur(10px);
  z-index: 5;

  .card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #000000b2;
    color: #fff;
    padding: .285rem 1rem;

    button {
      height: fit-content;
    }
  }

  .card-body {
    background-color: #0000009c;
  }

  .form-control {
    background-color: #000000a1 !important;
    border-color: #000 !important;
    color: #fff;
  }

  .material-icons-outlined.chevron {
    transition: .5s;
    color: #fff;
  }

  .material-icons-outlined.chevron.open {
    transform: rotate(-180deg);
  }
}

.card-template {
  border-radius: 8px;
  margin-top: 15px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.row-templates {
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
}

.card-preview {
  width: 100%;
}

.btn-link-preview .btn.max {
  font-size: 10px;
}

.icon-show-hide {
  font-size: 24px;
  color: #AAA;
}

.row-template-list {
  padding: 10px;
}

.row-template-list .col-sm-1 {
  padding-top: 3px;
}

.row-template-list .col-sm-9 span {
  margin-top: 4px;
}

.row-template-list .col-sm-2 {
  text-align: right;
  padding-right: 0px;
}

.row-template-list .col-sm-2 span {
  font-size: 24px;
  color: #AAAAAA;
  margin-top: 2px;
}

::v-deep #sidebar-right-import {
  background-color: var(--background-2) !important;
  width: 525px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.drop-item-upload {
  width: 100%;
  height: 350px;
  border: dashed 2px #697077;
  border-radius: 8px;
  margin-top: 18px;
  text-align: center;
}

.drop-item-upload h3 {
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.drop-item-upload button {
  border: 1px solid #6B23DC;
  background: #fff;
  padding: 6px 24px 6px 24px;
  border-radius: 8px;
  color: #6B23DC;
  margin-top: 15px;
  font-size: 14px;
}

.drop-item-upload img {
  margin-top: 60px;
}

.drop-item-upload-p {
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  color: #595959;
}

.calendar_form {
  position: absolute;
  right: 146px;
  width: 25px;

  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 100%;
}

.btn-tags {
  border: 1px solid var(--gray-font-color-3);
  border-radius: 25px;
  font-weight: 400;
  color: var(--gray-font-color-2);
}

/* Tags wrapper */
.tags-wrapper {
  position: relative;
  overflow-x: hidden;
  max-width: 85%;
  background-color: var(--background);
  border-radius: 13px;
  margin-left: -30px;

  .icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;

    .material-symbols-outlined {
      width: 40px;
      height: 40px;
      cursor: pointer;
      font-size: 1.2rem;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      background-color: var(--background);
      border: 1px solid var(--gray-font-color-3);

      &:hover {
        background: #efedfb;
      }
    }

    &:first-child {
      left: 0;
      display: none;
      background: linear-gradient(90deg, var(--background) 70%, transparent);

      & .material-symbols-outlined {
        margin-left: 30px;
      }
    }

    &:last-child {
      right: 0;
      display: none;
      justify-content: flex-end;
      background: linear-gradient(-90deg, var(--background) 70%, transparent);

      & .material-symbols-outlined {
        margin-right: 30px;
      }
    }
  }

  .tabs-box {
    display: flex;
    gap: 12px;
    list-style: none;
    margin-bottom: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-left: 0;

    &.dragging {
      scroll-behavior: auto;
      cursor: grab;

      & .tab {
        user-select: none;
        pointer-events: none;
      }
    }
  }

  .span-close {
    font-size: 20px;
    margin-top: 1px;
  }

  .tab {
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    background: var(--background);
    border-radius: 30px;
    border: 1px solid var(--gray-font-color-3);
    padding: 5px 12px;
    color: #595959;
    border: 2px solid #E5E5E5;
    display: flex;

    &.active {
      background-color: var(--clr-yup-purple);
      color: #FFF;
      border-color: transparent;
    }

    &.btn-settings {
      a {
        display: flex;
        color: var(--gray-font-color-4);
      }
    }
  }
}

.custom-control.custom-checkbox {
  margin-top: -15px;
  padding-left: 15px;
}

.fallback-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #fff;
}

.profile-photo {
  width: 32px;
  height: 32px;
  margin-right: 15px;

  figure {
    border-radius: 48px;
    background: #752de6;
    width: 32px;
    height: 32px;
    margin: 0px;
  }

  &:hover {
    color: #fc0;

    &~.profile-more-details {
      opacity: 1;
      z-index: 100;
    }
  }
}

@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  transform-origin: center;
  /* 4 */
}

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1, -1);
  /* 1, 2, 3 */
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

svg g text.circle-chart__percent {
  fill: #343D47;
  font-weight: 700;
}

svg g text.circle-chart__subline {
  fill: #C2C2C2;
  margin-top: 7px;
}

#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #cecece;
  stroke-width: 5px;
}

#svg #bar {
  stroke: #6B23DC;
}

#cont {
  display: block;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
  margin-top: 35px;
}

#cont:after {
  position: absolute;
  display: block;
  height: 160px;
  width: 160px;
  left: 50%;
  top: 50%;
  content: attr(data-pct)"%";
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  line-height: 160px;
  font-size: 2em;
}

.csv-file {
  width: 100%;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  margin: 0;
  margin-top: 15px;
}

.name-csv-div {
  width: 75%;
}

.name-csv-div span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #595959;
}

.csv-progress {
  width: 100%;
  height: 8px;
  background: #6B23DC;
  border-radius: 6px;
}

.name-csv-percent {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 10px;
}

.card-header-left {
  width: 72%;
}

.flex-row {
  flex-direction: row;
  display: flex;
}

.card-header-photo {
  background: #003969;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.card-header-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #0D0519;
}

.card-header-number {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-decoration-line: underline;
  color: #595959;
}

.card-header-number-text {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #AAA;
}

.card-header-sync {
  font-size: 16px;
  color: #697077;
  display: inline-block;
}

::v-deep #sidebar-right-numbers {
  background-color: var(--background-2) !important;
  width: 625px;

  small {
    color: var(--gray-font-color);
  }

  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}

.close-input {
  position: absolute;
  top: 8px;
  font-size: 20px;
  right: 10px;
}

@media (max-width: 1301px) {
  .card-header-left {
    width: 50%;
  }

  .card-header-right {
    width: 45%;
    padding-left: 10%;
  }
}

.info-whatsapp-button {
  display: flex;
  flex-direction: row;
}

.info-whatsapp-button .card-header-sync {
  margin-top: 4px;
}

.info-whatsapp-button .card-header-number {
  margin-left: 5px;
}

.info-var {
  color: #16CD08;
}

.info-var-tip {
  color: #6B23DC;
}

.info-var-tip-text {
  color: #AAA;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
}

.var-name-span {
  color: #AD1519;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.btn-files {
  border-left: 1px #E5E5E5 solid;
  padding-top: 5px;
  margin-top: 15px;
}

.btn-files span {
  color: #697077;
  margin-right: 15px;
  cursor: pointer;
}

.btn-files span:hover {
  color: #ccc;
}
::v-deep .template-content .card-preview{
  margin-top: 10px !important;
  width: 100% !important;
}
::v-deep .tags-container {
  .ti-input {
    border: none;
    padding: 0;
  }

  .form-control-send {
    padding: 3px !important;
    height: auto !important;
  }
  .vue-tags-input {
    min-height: 100px;
  }
}

.select-agent {
  margin-top: 10px;
  width: 58%;
  border-radius: 8px !important;
  border: 2px solid #E5E5E5 !important;
  color: #b0b0b0;
}
.calendarIcon {
}
.preview-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* align-items: center; */
  position: sticky;
  top: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (min-width: 768px) {
  .phone-img {
    position: absolute;
    right: 50px;
    padding-right: 3rem;
    margin-top: 10%;
    z-index: -1;
  }
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.header-wrapper {
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .card {
    // background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    width: 107px;
    height: 147px;
    /* &.card:not(:first-child) .card-header {
      padding: .85rem .5rem;
    } */
    .card-header {
      height: 100%;
      padding: .85rem .5rem;
      transition: .5s;
      box-shadow: 0px 0px 0px 0px #ffffff00;
      background: #fff !important;
      img {
        width: 100%;
        height: 61px;
        object-fit: none;
        object-position: 50% 0px;
      }
      &.active {
        box-shadow: 0px 0px 0px 1px var(--clr-yup-purple);
      }
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F2F2F2;
      width: 100%;
      height: 61px;

      span.material-symbols-outlined {
        color: var(--gray-font-color);
      }
    }
    .btn-choose-type {
      height: fit-content;
      padding: 0;
      font-weight: 400;
      font-size: .75rem;
    }
  }
}
/* body.darkmode {
  background-color: #ffffff !important;
} */
.info-var {
  color: #16CD08;
}

.info-var-tip {
  color: #6B23DC;
}

.info-var-tip-text {
  color: #AAA;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
}

.var-name-span {
  color: #AD1519;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.hr-divider {
  border: solid 1px #E4E7EC;
  width: 96%;
}
.content-upload {
  height: 200px;
  width: 100%;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  text-align: center;
  align-content: center;
}
.content-upload:hover {
  border: dashed 2px #ccc;
}
.content-upload span {
  color: #5A99EC;
  font-size: 50px;
}
.content-upload h4 {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}
.link-input {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #667085;
}
.card-rich-cover {
  border-radius: 8px;
  overflow: hidden;
}
.shadow-card {
  width: 300px;
  box-shadow: 1px 2px 3px 1px #0000000f;
  padding: 10px;
  border: solid #e7e7e7 1px;
}
.shadow-card label {
  color: #0D0519;
  font-weight: 400;
  font-size: 13px;
  margin-top: 5px;
}
.btn-add-action {
  width: 100%;
  background: #fff;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px;
  align-content: center;
  color: #101828;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.btn-add-action span {
  font-size: 16px;
  color: #101828;
}
.btn-add-action-hover{
  width: 100%;
  background: #fff;
  border: dashed 2px #6F6F6F;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px;
  align-content: center;
  color: #101828;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  display: none;
}
.btn-add-action-hover span {
  flex: 1;
  font-size: 19px;
}
.btn-add-action-hover span:nth-child(1) {
  color: #752DE6 !important;
}
.btn-add-action-hover span:nth-child(3) {
  color: #0099FF !important;
}
.btn-add-action-hover span:nth-child(4) {
  color: #870E1E !important;
}
.btn-add-action-hover span:nth-child(5) {
  color: #16870E !important;
}
.btn-actions:hover .btn-add-action-hover {
  display: flex;
}
.btn-actions:hover .btn-add-action{
  display: none;
}
.content-upload {
  cursor: pointer;
}
.content-upload input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.file-unic {
  float: none !important;
  margin: 0 auto !important;
}
.btn-add-template {
  background: none !important;
  border: none !important;
  color: var(--clr-yup-purple) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: flex !important;
  padding: 0px !important;
  flex-direction: row-reverse;
  align-self: flex-end;
  flex: 1;
}
.content-message {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
}
::v-deep .card-edit-btn {
  margin: 10px;
  color: #fff;
  cursor: pointer;
  left: 0;
}
::v-deep .card-edit-btn i {
  color: #fff !important;
}
::v-deep .card-edit-btn:hover {
  color: rgb(230, 230, 230);
}
.cropper {
  position: absolute;
  top: 10px;
  right: 30px;
}
.remove-card-icon {
  position: absolute;
  color: #fff;
  right: 15px;
  top: 7px;
}
::v-deep .rebranding .vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
  width: 79%;
}
</style>
